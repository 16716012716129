<template>
  <div class="subpage-content">
    <div class="post">
      <div
        class="title-area"
        :style="{
          background: 'url(' + post.image + ')',
          backgroundSize: 'cover'
        }"
      >
        <div class="title">
          <div class="post-title">
            <h1>{{ post.title }}</h1>
            <p class="date">Written on {{ dateInWords(post.created_at) }}</p>
            <div class="social">
              <a
                :href="post.twitter"
                style="margin-right: 10px;"
                onclick="window.open(this.href, 'mywin',
'left=20,top=20,width=500,height=500,toolbar=1,resizable=0'); return false;"
                class="button facebook"
                ><i class="fa fa-facebook"></i> - Share it!</a
              >

              <a
                :href="post.facebook"
                onclick="window.open(this.href, 'mywin',
'left=20,top=20,width=500,height=500,toolbar=1,resizable=0'); return false;"
                class="button twitter"
                ><i class="fa fa-twitter"></i> - Tweet it!</a
              >
            </div>
          </div>
        </div>
      </div>

      <div class="post-content">
        <div v-html="post.content"></div>
        <p class="tag"><strong>Tags:</strong> {{ post.tags }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import posts from '@/assets/posts.json'
import dayjs from 'dayjs'
export default {
  data () {
    return {
      posts
    }
  },
  computed: {
    post: function () {
      return this.posts.filter(post => post.slug === this.$route.params.id)[0]
    }
  },
  methods: {
    dateInWords (timestamp) {
      return dayjs(timestamp).format('dddd, MMMM D, YYYY')
    }
  }
}
</script>
