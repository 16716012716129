<template>
  <div>
    <a id="top"></a>

    <div class="popular-posts">
      <h2 style="margin-top: 130px">Post Archive</h2>

      <div class="posts">
        <div
          v-for="post in posts.slice().reverse()"
          :key="post.id"
          class="post"
          :style="{
            background: 'url(' + post.image + ')',
            backgroundSize: 'cover'
          }"
        >
          <div class="post-content">
            <h1>
              {{ post.title }}
            </h1>
            <p>Written in: {{ getMonth(post.created_at) }}</p>
            <router-link
              :to="'/posts/' + post.slug"
              class="button"
              style="margin-right: 5px;"
              >Read More</router-link
            >
            <a
              :href="post.twitter"
              onclick="window.open(this.href, 'mywin',
'left=20,top=20,width=500,height=500,toolbar=1,resizable=0'); return false;"
              class="button twitter"
              style="margin-right: 5px;"
              ><i class="fa fa-twitter"></i
            ></a>
            <a
              :href="post.facebook"
              class="button facebook"
              onclick="window.open(this.href, 'mywin',
'left=20,top=20,width=500,height=500,toolbar=1,resizable=0'); return false;"
              style="margin-right: 5px;"
              ><i class="fa fa-facebook"></i
            ></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import posts from '@/assets/posts.json'
import dayjs from 'dayjs'
export default {
  data () {
    return {
      posts
    }
  },
  methods: {
    getMonth: function (timestamp) {
      return dayjs(timestamp).format('MMM')
    }
  }
}
</script>
